<script setup lang="ts">
const props = defineProps<{
  containerClassName?: string
}>()
</script>

<template>
  <section class="glxBlock">
    <div class="container" :class="props.containerClassName">
      <slot></slot>
    </div>
  </section>
</template>

<style scoped lang="scss">
.glxBlock {
  display: flex;
  height: 100%;
  align-items: stretch;
  justify-content: center;

  margin-left: auto;
  margin-right: auto;

  max-width: 100%;

  .container {
    //width: $w1920;
    //flex-basis: $w1920;
    flex-grow: 1;
    min-width: $w1200;
  }
}
</style>